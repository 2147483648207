import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import Preloader from "../components/Preloader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Dashboard = () => {
  const [users, setUsers] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [transactionsLength, setTransactionsLength] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("firstwebster-admin");

    const getUsers = async () => {
      const { data } = await axios.get(
        "https://api.firstwebster.com/api/user/find",
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setUsers(data);
    };

    getUsers();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("firstwebster-admin");

    const getTransactions = async () => {
      const { data } = await axios.get(
        "https://api.firstwebster.com/api/transaction?new=true",
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setTransactions(data);
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
    };

    getTransactions();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("firstwebster-admin");

    const getTransactions = async () => {
      const { data } = await axios.get(
        "https://api.firstwebster.com/api/transaction",
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setTransactionsLength(data.length);
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
    };

    getTransactions();
  }, []);

  const approve = async () => {
    const token = localStorage.getItem("firstwebster-admin");

    setLoading(true);

    try {
      await axios.put(
        "https://api.firstwebster.com/api/transaction/approve",
        { transactionId },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      toast.success("Transaction approved!");
      setTimeout(() => {
        navigate(0);
      }, 1000);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const reject = async () => {
    const token = localStorage.getItem("firstwebster-admin");

    setLoading(true);

    try {
      await axios.put(
        "https://api.firstwebster.com/api/transaction/reject",
        { transactionId },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      toast.success("Transaction rejected!");
      setTimeout(() => {
        navigate(0);
      }, 1000);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const deleteTransaction = async () => {
    const token = localStorage.getItem("firstwebster-admin");

    setLoading(true);

    try {
      await axios.delete(
        `https://api.firstwebster.com/api/transaction/${transactionId}`,

        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      toast.success("Transaction deleted!");
      setTimeout(() => {
        navigate(0);
      }, 1000);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      {pageLoading ? (
        <Preloader />
      ) : (
        <div className="nk-body bg-lighter npc-general has-sidebar ui-bordered ">
          <div className="nk-app-root">
            <div className="nk-main ">
              <Sidebar />
              <div className="nk-wrap ">
                <Header />
                <div className="nk-content ">
                  <div className="container-fluid">
                    <div className="nk-content-inner">
                      <div className="nk-content-body">
                        <div className="nk-block-head nk-block-head-sm">
                          <div className="nk-block-between">
                            <div className="nk-block-head-content">
                              <h3 className="nk-block-title page-title">
                                Dashboard
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="nk-block">
                          <div className="row g-gs">
                            <div className="col-xxl-3 col-sm-6">
                              <div className="card">
                                <div className="nk-ecwg nk-ecwg6">
                                  <div className="card-inner">
                                    <div className="card-title-group">
                                      <div className="card-title">
                                        <h6 className="title">Total Users</h6>
                                      </div>
                                    </div>
                                    <div className="data">
                                      <div className="data-group">
                                        <div className="amount">
                                          {users?.length?.toLocaleString()}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xxl-3 col-sm-6">
                              <div className="card">
                                <div className="nk-ecwg nk-ecwg6">
                                  <div className="card-inner">
                                    <div className="card-title-group">
                                      <div className="card-title">
                                        <h6 className="title">
                                          Total Transactions
                                        </h6>
                                      </div>
                                    </div>
                                    <div className="data">
                                      <div className="data-group">
                                        <div className="amount">
                                          {transactionsLength?.toLocaleString()}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xxl-8">
                              <div className="card card-full">
                                <div className="card-inner">
                                  <div className="card-title-group">
                                    <div className="card-title">
                                      <h6 className="title">
                                        Recent Transactions
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                                {transactions?.length > 0 && (
                                  <div className="nk-tb-list is-separate is-medium mb-3">
                                    <div className="nk-tb-item nk-tb-head">
                                      <div className="nk-tb-col">
                                        <span>ID</span>
                                      </div>
                                      <div className="nk-tb-col tb-col-md">
                                        <span>Date</span>
                                      </div>
                                      <div className="nk-tb-col">
                                        <span className="d-none d-sm-block">
                                          Status
                                        </span>
                                      </div>
                                      <div className="nk-tb-col tb-col-sm">
                                        <span>User</span>
                                      </div>
                                      <div className="nk-tb-col tb-col-md">
                                        <span>Type</span>
                                      </div>
                                      <div className="nk-tb-col">
                                        <span>Amount</span>
                                      </div>
                                      <div className="nk-tb-col nk-tb-col-tools"></div>
                                    </div>
                                    {transactions?.map((transaction: any) => (
                                      <div
                                        className="nk-tb-item"
                                        key={transaction._id}
                                      >
                                        <div className="nk-tb-col">
                                          <span className="tb-lead">
                                            <a href="#/">
                                              #{transaction.transactionId}
                                            </a>
                                          </span>
                                        </div>
                                        <div className="nk-tb-col tb-col-md">
                                          <span className="tb-sub">
                                            {new Date(
                                              transaction?.dateTime
                                            )?.toDateString()}
                                          </span>
                                        </div>
                                        {transaction.status === "Pending" && (
                                          <div className="nk-tb-col">
                                            <span className="dot bg-warning d-sm-none" />
                                            <span className="badge badge-sm badge-dot has-bg bg-warning d-none d-sm-inline-flex">
                                              Pending
                                            </span>
                                          </div>
                                        )}
                                        {transaction.status ===
                                          "Unconfirmed" && (
                                          <div className="nk-tb-col">
                                            <span className="dot bg-warning d-sm-none" />
                                            <span className="badge badge-sm badge-dot has-bg bg-warning d-none d-sm-inline-flex">
                                              Unconfirmed
                                            </span>
                                          </div>
                                        )}
                                        {transaction.status === "Completed" && (
                                          <div className="nk-tb-col">
                                            <span className="dot bg-success d-sm-none" />
                                            <span className="badge badge-sm badge-dot has-bg bg-success d-none d-sm-inline-flex">
                                              Completed
                                            </span>
                                          </div>
                                        )}
                                        {transaction.status === "Cancelled" && (
                                          <div className="nk-tb-col">
                                            <span className="dot bg-danger d-sm-none" />
                                            <span className="badge badge-sm badge-dot has-bg bg-danger d-none d-sm-inline-flex">
                                              Cancelled
                                            </span>
                                          </div>
                                        )}
                                        <div className="nk-tb-col tb-col-sm">
                                          <span className="tb-sub">
                                            {transaction.user.firstName}{" "}
                                            {transaction.user.lastName}
                                          </span>
                                        </div>
                                        <div className="nk-tb-col tb-col-md">
                                          <span className="tb-sub text-primary">
                                            {transaction.type}
                                          </span>
                                        </div>
                                        <div className="nk-tb-col">
                                          <span className="tb-lead">
                                            $
                                            {transaction.amount?.toLocaleString()}
                                          </span>
                                        </div>
                                        <div className="nk-tb-col nk-tb-col-tools">
                                          <ul className="nk-tb-actions gx-1">
                                            <li>
                                              <div className="drodown me-n1">
                                                <a
                                                  href="#/"
                                                  className="dropdown-toggle btn btn-icon btn-trigger"
                                                  data-bs-toggle="dropdown"
                                                >
                                                  <em className="icon ni ni-more-h" />
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-end">
                                                  <ul className="link-list-opt no-bdr">
                                                    <li>
                                                      <Link
                                                        to={`/transaction/${transaction.transactionId}`}
                                                      >
                                                        <em className="icon ni ni-eye" />
                                                        <span>
                                                          Transaction Details
                                                        </span>
                                                      </Link>
                                                    </li>
                                                    {(transaction.status ===
                                                      "Pending" ||
                                                      transaction.status ===
                                                        "Unconfirmed") && (
                                                      <li>
                                                        <a
                                                          href="#/"
                                                          onClick={() => {
                                                            setTransactionId(
                                                              transaction.transactionId
                                                            );
                                                            setApproveModalOpen(
                                                              true
                                                            );
                                                          }}
                                                        >
                                                          <em className="icon ni ni-money" />
                                                          <span>Approve</span>
                                                        </a>
                                                      </li>
                                                    )}
                                                    {(transaction.status ===
                                                      "Pending" ||
                                                      transaction.status ===
                                                        "Unconfirmed") && (
                                                      <li>
                                                        <a
                                                          href="#/"
                                                          onClick={() => {
                                                            setTransactionId(
                                                              transaction.transactionId
                                                            );
                                                            setRejectModalOpen(
                                                              true
                                                            );
                                                          }}
                                                        >
                                                          <em className="icon ni ni-trash" />
                                                          <span>Reject</span>
                                                        </a>
                                                      </li>
                                                    )}
                                                    <li>
                                                      <a
                                                        href="#/"
                                                        onClick={() => {
                                                          setTransactionId(
                                                            transaction.transactionId
                                                          );
                                                          setDeleteModalOpen(
                                                            true
                                                          );
                                                        }}
                                                      >
                                                        <em className="icon ni ni-trash" />
                                                        <span>Delete</span>
                                                      </a>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Footer />
              </div>
            </div>
          </div>
        </div>
      )}

      <ToastContainer
        position="bottom-center"
        theme="dark"
        autoClose={3000}
        closeButton={false}
      />

      {approveModalOpen && (
        <>
          <div
            className="modal fade show"
            tabIndex={-1}
            id="modalAlert2"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  href="#/"
                  className="close"
                  data-bs-dismiss="modal"
                  onClick={() => setApproveModalOpen(false)}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-info bg-info" />
                    <h4 className="nk-modal-title">Approve Transaction</h4>
                    <div className="nk-modal-text">
                      <p className="lead">
                        Are you sure you want to approve this transaction?
                      </p>
                    </div>
                    <div className="nk-modal-action mt-5">
                      {loading ? (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                        >
                          Proceeding...
                        </a>
                      ) : (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                          onClick={approve}
                        >
                          Proceed
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={() => setApproveModalOpen(false)}
          />
        </>
      )}

      {rejectModalOpen && (
        <>
          <div
            className="modal fade show"
            tabIndex={-1}
            id="modalAlert2"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  href="#/"
                  className="close"
                  data-bs-dismiss="modal"
                  onClick={() => setRejectModalOpen(false)}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-info bg-info" />
                    <h4 className="nk-modal-title">Reject Transaction</h4>
                    <div className="nk-modal-text">
                      <p className="lead">
                        Are you sure you want to reject this transaction?
                      </p>
                    </div>

                    <div className="nk-modal-action mt-5">
                      {loading ? (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                        >
                          Proceeding...
                        </a>
                      ) : (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                          onClick={reject}
                        >
                          Proceed
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={() => setRejectModalOpen(false)}
          />
        </>
      )}

      {deleteModalOpen && (
        <>
          <div
            className="modal fade show"
            tabIndex={-1}
            id="modalAlert2"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  href="#/"
                  className="close"
                  data-bs-dismiss="modal"
                  onClick={() => setDeleteModalOpen(false)}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-info bg-info" />
                    <h4 className="nk-modal-title">Delete Transaction</h4>
                    <div className="nk-modal-text">
                      <p className="lead">
                        Are you sure you want to delete this transaction?
                      </p>
                    </div>

                    <div className="nk-modal-action mt-5">
                      {loading ? (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                        >
                          Proceeding...
                        </a>
                      ) : (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                          onClick={deleteTransaction}
                        >
                          Proceed
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={() => setDeleteModalOpen(false)}
          />
        </>
      )}
    </>
  );
};

export default Dashboard;
