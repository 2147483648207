import { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Preloader from "../components/Preloader";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TransactionDetails = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [transactionDetails, setTransactionDetails] = useState<any>({});
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { transactionId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("firstwebster-admin");

    const getDetails = async () => {
      const { data } = await axios.get(
        `https://api.firstwebster.com/api/transaction/find/${transactionId}`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setTransactionDetails(data);
      setPageLoading(false);
    };

    getDetails();
  }, [transactionId]);

  const approve = async () => {
    const token = localStorage.getItem("firstwebster-admin");

    setLoading(true);

    try {
      await axios.put(
        "https://api.firstwebster.com/api/transaction/approve",
        { transactionId },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      toast.success("Transaction approved!");
      setTimeout(() => {
        navigate(0);
      }, 1000);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const reject = async () => {
    const token = localStorage.getItem("firstwebster-admin");

    setLoading(true);

    try {
      await axios.put(
        "https://api.firstwebster.com/api/transaction/reject",
        { transactionId },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      toast.success("Transaction rejected!");
      setTimeout(() => {
        navigate(0);
      }, 1000);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const deleteTransaction = async () => {
    const token = localStorage.getItem("firstwebster-admin");

    setLoading(true);

    try {
      await axios.delete(
        `https://api.firstwebster.com/api/transaction/${transactionId}`,

        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      toast.success("Transaction deleted!");
      setTimeout(() => {
        navigate(0);
      }, 1000);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      {pageLoading ? (
        <Preloader />
      ) : (
        <div className="nk-body bg-lighter npc-general has-sidebar ui-bordered ">
          <div className="nk-app-root">
            <div className="nk-main ">
              <Sidebar />
              <div className="nk-wrap ">
                <Header />
                <div className="nk-content ">
                  <div className="container-fluid">
                    <div className="nk-content-inner">
                      <div className="nk-content-body">
                        <div className="nk-block-head nk-block-head-sm">
                          <div className="nk-block-between g-3">
                            <div className="nk-block-head-content">
                              <h3 className="nk-block-title page-title">
                                Transactions /{" "}
                                <strong className="text-primary small">
                                  {transactionDetails?.transactionId}
                                </strong>
                              </h3>
                            </div>
                            <div className="nk-block-head-content">
                              <Link
                                to="/transactions"
                                className="btn btn-outline-light bg-white d-none d-sm-inline-flex"
                              >
                                <em className="icon ni ni-arrow-left" />
                                <span>Back</span>
                              </Link>
                              <Link
                                to="/transactions"
                                className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                              >
                                <em className="icon ni ni-arrow-left" />
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="nk-block">
                          <div className="card">
                            <div className="card-aside-wrap">
                              <div className="card-content">
                                {transactionDetails.status === "Pending" && (
                                  <ul
                                    className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card"
                                    style={{
                                      flexDirection: "row-reverse",
                                    }}
                                  >
                                    <li>
                                      <div className="drodown me-n1">
                                        <a
                                          href="#/"
                                          className="dropdown-toggle btn btn-icon btn-trigger"
                                          data-bs-toggle="dropdown"
                                        >
                                          <em className="icon ni ni-more-h" />
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-end">
                                          <ul className="link-list-opt no-bdr">
                                            <li>
                                              <a
                                                href="#/"
                                                onClick={() => {
                                                  setApproveModalOpen(true);
                                                }}
                                              >
                                                <em className="icon ni ni-money" />
                                                <span>Approve Transaction</span>
                                              </a>
                                            </li>

                                            <li>
                                              <a
                                                href="#/"
                                                onClick={() => {
                                                  setRejectModalOpen(true);
                                                }}
                                              >
                                                <em className="icon ni ni-trash" />
                                                <span>Reject Transaction</span>
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="#/"
                                                onClick={() => {
                                                  setDeleteModalOpen(true);
                                                }}
                                              >
                                                <em className="icon ni ni-trash" />
                                                <span>Delete Transaction</span>
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                )}
                                {transactionDetails.status ===
                                  "Unconfirmed" && (
                                  <ul
                                    className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card"
                                    style={{
                                      flexDirection: "row-reverse",
                                    }}
                                  >
                                    <li>
                                      <div className="drodown me-n1">
                                        <a
                                          href="#/"
                                          className="dropdown-toggle btn btn-icon btn-trigger"
                                          data-bs-toggle="dropdown"
                                        >
                                          <em className="icon ni ni-more-h" />
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-end">
                                          <ul className="link-list-opt no-bdr">
                                            <li>
                                              <a
                                                href="#/"
                                                onClick={() => {
                                                  setApproveModalOpen(true);
                                                }}
                                              >
                                                <em className="icon ni ni-money" />
                                                <span>Approve Transaction</span>
                                              </a>
                                            </li>

                                            <li>
                                              <a
                                                href="#/"
                                                onClick={() => {
                                                  setRejectModalOpen(true);
                                                }}
                                              >
                                                <em className="icon ni ni-trash" />
                                                <span>Reject Transaction</span>
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="#/"
                                                onClick={() => {
                                                  setDeleteModalOpen(true);
                                                }}
                                              >
                                                <em className="icon ni ni-trash" />
                                                <span>Delete Transaction</span>
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                )}
                                {transactionDetails.status !== "Pending" &&
                                  transactionDetails.status !==
                                    "Unconfirmed" && (
                                    <ul
                                      className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card"
                                      style={{
                                        flexDirection: "row-reverse",
                                      }}
                                    >
                                      <li>
                                        <div className="drodown me-n1">
                                          <a
                                            href="#/"
                                            className="dropdown-toggle btn btn-icon btn-trigger"
                                            data-bs-toggle="dropdown"
                                          >
                                            <em className="icon ni ni-more-h" />
                                          </a>
                                          <div className="dropdown-menu dropdown-menu-end">
                                            <ul className="link-list-opt no-bdr">
                                              <li>
                                                <a
                                                  href="#/"
                                                  onClick={() => {
                                                    setDeleteModalOpen(true);
                                                  }}
                                                >
                                                  <em className="icon ni ni-trash" />
                                                  <span>
                                                    Delete Transaction
                                                  </span>
                                                </a>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  )}

                                <div className="card-inner">
                                  <div className="nk-block">
                                    <div className="nk-block-head">
                                      <h6 className="title overline-title text-base">
                                        Transaction Details
                                      </h6>
                                    </div>
                                    <div className="profile-ud-list">
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Transaction ID
                                          </span>
                                          <span className="profile-ud-value">
                                            {transactionDetails.transactionId}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Transaction Type
                                          </span>
                                          <span className="profile-ud-value">
                                            {transactionDetails.type}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Amount
                                          </span>
                                          <span className="profile-ud-value">
                                            $
                                            {transactionDetails.amount?.toLocaleString()}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            OTP
                                          </span>
                                          <span className="profile-ud-value">
                                            {transactionDetails.otp}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Date/Time
                                          </span>
                                          <span className="profile-ud-value">
                                            {new Date(
                                              transactionDetails?.dateTime
                                            )?.toLocaleString()}
                                          </span>
                                        </div>
                                      </div>
                                      {transactionDetails?.document && (
                                        <div className="profile-ud-item">
                                          <div className="profile-ud wider">
                                            <span className="profile-ud-label">
                                              Document
                                            </span>
                                            <span className="profile-ud-value">
                                              <a
                                                href={
                                                  transactionDetails.document
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                View
                                              </a>
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                      {transactionDetails.type ===
                                        "Withdrawal" && (
                                        <div className="profile-ud-item">
                                          <div className="profile-ud wider">
                                            <span className="profile-ud-label">
                                              Transfer Option
                                            </span>
                                            <span className="profile-ud-value">
                                              {
                                                transactionDetails.transferOption
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Description
                                          </span>
                                          <span className="profile-ud-value">
                                            {transactionDetails.description}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Status
                                          </span>
                                          <span className="profile-ud-value">
                                            {transactionDetails.status}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="nk-block">
                                    <div className="nk-block-head nk-block-head-line">
                                      <h6 className="title overline-title text-base">
                                        User Details
                                      </h6>
                                    </div>
                                    <div className="profile-ud-list">
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Full name
                                          </span>
                                          <span className="profile-ud-value">
                                            {transactionDetails.user.firstName}{" "}
                                            {transactionDetails.user.middleName}{" "}
                                            {transactionDetails.user.lastName}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Mobile Number
                                          </span>
                                          <span className="profile-ud-value">
                                            {transactionDetails.user.phone}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Email Address
                                          </span>
                                          <span className="profile-ud-value">
                                            {transactionDetails.user.email}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Account Number
                                          </span>
                                          <span className="profile-ud-value">
                                            {
                                              transactionDetails.user
                                                .accountNumber
                                            }
                                          </span>
                                        </div>
                                      </div>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Account Balance
                                          </span>
                                          <span className="profile-ud-value">
                                            $
                                            {transactionDetails.user.balance?.toLocaleString()}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="nk-block">
                                    <div className="nk-block-head nk-block-head-line">
                                      <h6 className="title overline-title text-base">
                                        Payment Details
                                      </h6>
                                    </div>
                                    {transactionDetails?.type ===
                                      "Withdrawal" && (
                                      <div className="profile-ud-list">
                                        <div className="profile-ud-item">
                                          <div className="profile-ud wider">
                                            <span className="profile-ud-label">
                                              Bank Name
                                            </span>
                                            <span className="profile-ud-value">
                                              {
                                                transactionDetails?.bankDetails
                                                  ?.bankName
                                              }
                                            </span>
                                          </div>
                                        </div>
                                        <div className="profile-ud-item">
                                          <div className="profile-ud wider">
                                            <span className="profile-ud-label">
                                              Account Name
                                            </span>
                                            <span className="profile-ud-value">
                                              {
                                                transactionDetails?.bankDetails
                                                  ?.accountName
                                              }
                                            </span>
                                          </div>
                                        </div>
                                        <div className="profile-ud-item">
                                          <div className="profile-ud wider">
                                            <span className="profile-ud-label">
                                              Account Number
                                            </span>
                                            <span className="profile-ud-value">
                                              {
                                                transactionDetails?.bankDetails
                                                  ?.accountNumber
                                              }
                                            </span>
                                          </div>
                                        </div>
                                        <div className="profile-ud-item">
                                          <div className="profile-ud wider">
                                            <span className="profile-ud-label">
                                              Account Type
                                            </span>
                                            <span className="profile-ud-value">
                                              {
                                                transactionDetails?.bankDetails
                                                  ?.accountType
                                              }
                                            </span>
                                          </div>
                                        </div>
                                        {transactionDetails.bankDetails
                                          ?.routingNumber && (
                                          <div className="profile-ud-item">
                                            <div className="profile-ud wider">
                                              <span className="profile-ud-label">
                                                Routing Number
                                              </span>
                                              <span className="profile-ud-value">
                                                {
                                                  transactionDetails
                                                    ?.bankDetails?.routingNumber
                                                }
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {transactionDetails.bankDetails
                                          ?.swiftCode && (
                                          <div className="profile-ud-item">
                                            <div className="profile-ud wider">
                                              <span className="profile-ud-label">
                                                Swift Code
                                              </span>
                                              <span className="profile-ud-value">
                                                {
                                                  transactionDetails
                                                    ?.bankDetails?.swiftCode
                                                }
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {transactionDetails.bankDetails
                                          ?.country && (
                                          <div className="profile-ud-item">
                                            <div className="profile-ud wider">
                                              <span className="profile-ud-label">
                                                Country
                                              </span>
                                              <span className="profile-ud-value">
                                                {
                                                  transactionDetails
                                                    ?.bankDetails?.country
                                                }
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  <div className="nk-divider divider md" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      )}

      <ToastContainer
        position="bottom-center"
        theme="dark"
        autoClose={3000}
        closeButton={false}
      />

      {approveModalOpen && (
        <>
          <div
            className="modal fade show"
            tabIndex={-1}
            id="modalAlert2"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  href="#/"
                  className="close"
                  data-bs-dismiss="modal"
                  onClick={() => setApproveModalOpen(false)}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-info bg-info" />
                    <h4 className="nk-modal-title">Approve Transaction</h4>
                    <div className="nk-modal-text">
                      <p className="lead">
                        Are you sure you want to approve this transaction?
                      </p>
                    </div>
                    <div className="nk-modal-action mt-5">
                      {loading ? (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                        >
                          Proceeding...
                        </a>
                      ) : (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                          onClick={approve}
                        >
                          Proceed
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={() => setApproveModalOpen(false)}
          />
        </>
      )}

      {rejectModalOpen && (
        <>
          <div
            className="modal fade show"
            tabIndex={-1}
            id="modalAlert2"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  href="#/"
                  className="close"
                  data-bs-dismiss="modal"
                  onClick={() => setRejectModalOpen(false)}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-info bg-info" />
                    <h4 className="nk-modal-title">Reject Transaction</h4>
                    <div className="nk-modal-text">
                      <p className="lead">
                        Are you sure you want to reject this transaction?
                      </p>
                    </div>

                    <div className="nk-modal-action mt-5">
                      {loading ? (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                        >
                          Proceeding...
                        </a>
                      ) : (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                          onClick={reject}
                        >
                          Proceed
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={() => setRejectModalOpen(false)}
          />
        </>
      )}

      {deleteModalOpen && (
        <>
          <div
            className="modal fade show"
            tabIndex={-1}
            id="modalAlert2"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  href="#/"
                  className="close"
                  data-bs-dismiss="modal"
                  onClick={() => setDeleteModalOpen(false)}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-info bg-info" />
                    <h4 className="nk-modal-title">Delete Transaction</h4>
                    <div className="nk-modal-text">
                      <p className="lead">
                        Are you sure you want to delete this transaction?
                      </p>
                    </div>

                    <div className="nk-modal-action mt-5">
                      {loading ? (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                        >
                          Proceeding...
                        </a>
                      ) : (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                          onClick={deleteTransaction}
                        >
                          Proceed
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={() => setDeleteModalOpen(false)}
          />
        </>
      )}
    </>
  );
};

export default TransactionDetails;
